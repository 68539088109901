<template>
  <v-container fluid>

    <v-card tile>

      <v-card-title>
        User roles
        <v-spacer></v-spacer>
        <v-text-field
          dense
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
        :loading="loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:item.roles="{ item }">
          <v-chip
            v-for="role in item.roles"
            :key="role.id"
            color="green"
            dark
            filter
            link
            class="mr-1"
          >
            {{ role.name.substring(5) }}
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            class="mr-2"
            color="blue"
            @click="openEditUserRolesDialog(item)"
          >
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="editUserRolesDialog"
      persistent
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Edit user roles</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-select
              v-model="user.roles"
              :items="roles"
              :item-text="(item) => item.name"
              :item-value="(item) => item.id"
              return-object
              multiple
              chips
              label="Roles"
            >
            </v-select>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editUserRolesDialog = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="editUserRoles">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

</template>

<script>
export default {
  data () {
    return {
      user: Object(),
      users: [],
      roles: [],
      editUserRolesDialog: false,
      loading: true,
      search: '',
      headers: [
        { text: 'UID', value: 'id', width: '90px' },
        { text: 'NAME', value: 'name' },
        { text: 'SURNAME', value: 'surname' },
        { text: 'ROLES', value: 'roles' },
        { text: 'ACTIONS', value: 'actions', sortable: false, width: '90px' }
      ]
    }
  },

  methods: {
    openEditUserRolesDialog (item) {
      this.user = item
      this.editUserRolesDialog = true
    },

    async editUserRoles () {
      this.editUserRolesDialog = false
      await this.$store.dispatch('user/updateUser', this.user)
      this.user = Object()
    }
  },

  async mounted () {
    document.title = 'Users | Masarka Student Club'
    this.loading = true
    this.users = await this.$store.dispatch('user/fetchAllUsers')
    this.roles = await this.$store.dispatch('rest/fetchAllItems', { path: 'roles' })
    this.loading = false
  }
}
</script>
